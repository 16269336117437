import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    CanActivate,
    RouterStateSnapshot,
} from '@angular/router';
import { Router } from '@angular/router';

import { AuthService } from './auth.service';

@Injectable()
export class LoginGuard implements CanActivate {
    authService;
    router;

    constructor(authService: AuthService, router: Router) {
        this.authService = authService;
        this.router = router;
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.handleLoginCheck(route, state).catch((err) => {
            console.error(err);
            return false;
        });
    }

    async handleLoginCheck(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Promise<boolean> {
        let can = await this.authService.isLoggedIn();
        if (!can) {
            return this.authService.logout(state.url);
        } else {
            if (!this.authService.currentAccount) {
                await this.authService.setCurrentAccount();
            }
            this.authService.registerSelf();
            return true;
        }
    }
}
