import { Component, OnInit, Input, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { AuthService } from '../../components/auth/auth.service';
import { UserService } from '../../services/user.service';
import { CognitoService } from '../auth/cognito.service';
import qrcode from 'qrcode';

@Component({
    selector: 'totp-modal',
    templateUrl: './totp.modal.html',
    styleUrls: ['./totp.modal.scss'],
})
export class TOTPModal implements OnInit {
    @Input() public mfa: { enrolled: boolean };
    @Input() public forced = false;
    @Input() public disableCancel = false;
    @Input() public cognito = false;
    @Input() public cognitoUser;
    errorStates: {
        totpFail: boolean;
        totpEnrollFail: boolean;
    } = {
        totpFail: false,
        totpEnrollFail: false,
    };
    qrImage: string;
    key: string;
    verifyOTP: string;
    OTP: string;
    rememberDevice = false;

    constructor(
        public authService: AuthService,
        public userService: UserService,
        public activeModal: NgbActiveModal,
        public cognitoService: CognitoService,
        private router: Router,
        private ngZone: NgZone
    ) {}

    ngOnInit() {
        // TODO: Focus target <09-10-20, Liaan> //
        if (this.mfa.enrolled) {
            //Focus OTP
        } else {
            //Focus OTP
        }
    }

    requestSetup() {
        if (this.cognito) {
            this.cognitoService.Auth.setupTOTP(this.cognitoUser)
                .then(async (code) => {
                    this.key = code;
                    const uri = `otpauth://totp/AWSCognito:${this.cognitoUser.username}?secret=${code}&issuer=NoKey TOTP`;
                    this.qrImage = await qrcode.toDataURL(uri);
                })
                .catch((err) => console.error(err));
        } else if (!this.mfa.enrolled) {
            //this.$http.get('/api/users/mfasetup').then( (res) => {
            this.userService
                .getMFASetup()
                .toPromise()
                .then((data) => {
                    if (data && data.qrImage && data.key) {
                        this.qrImage = data.qrImage;
                        this.key = data.key;
                    } else {
                        throw new Error('Setup failed');
                    }
                })
                .catch((err) => {
                    console.error(err);
                });
        }
    }

    verifyCode() {
        if (this.cognito) {
            this.cognitoService.Auth.verifyTotpToken(
                this.cognitoUser,
                this.verifyOTP
            )
                .then(async () => {
                    await this.cognitoService.Auth.setPreferredMFA(
                        this.cognitoUser,
                        'SOFTWARE_TOKEN_MFA'
                    );
                    if (this.rememberDevice) {
                        await this.cognitoService.Auth.rememberDevice();
                    }
                    this.ngZone.run(() => {
                        this.router.navigate(['accPlaceholder/home']);
                    });
                    return null;
                })
                .then(() => {
                    this.activeModal.close();
                })
                .catch((err) => console.error(err));
        } else {
            return this.userService
                .MFASetup(this.verifyOTP)
                .toPromise()
                .then((data) => {
                    if (data) {
                        return this.mfaLogin(this.verifyOTP);
                    }
                })
                .catch((err) => {
                    this.errorStates.totpEnrollFail = true;
                });
        }
    }

    mfaLogin(code: string) {
        const self = this;
        let promise: Promise<any>;
        if (this.cognito) {
            promise = this.cognitoService.Auth.confirmSignIn(
                this.cognitoUser,
                code,
                'SOFTWARE_TOKEN_MFA'
            ).then(async () => {
                if (this.rememberDevice) {
                    await this.cognitoService.Auth.rememberDevice();
                }
            });
        } else {
            promise = this.authService.mfaLogin({ code: code }).then(() => {
                //Reauth primus
                //
                //If initial login - redirect else reload window
            });
        }
        promise
            .then(() => {
                if (this.activeModal) {
                    this.activeModal.close();
                }
            })
            .catch((err) => {
                console.error(err);
                this.errorStates.totpFail = true;
                return null;
            });
    }

    ok() {}

    doLog() {
        console.debug(this);
    }

    cancel() {
        this.activeModal.close();
    }
}
