import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from './auth.service';
import { Ability } from '@casl/ability';

@Injectable()
export class AbilityGuard implements CanActivate {
    authService;
    router;

    constructor(
        authService: AuthService,
        router: Router,
        private ability: Ability
    ) {
        this.authService = authService;
        this.router = router;
    }

    async canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ) {
        const self = this;
        if (route.data?.subject) {
            if (!this.authService.currentAccount) {
                const accountId = route.paramMap.get('accountId');
                let account = await this.authService.setCurrentAccount(
                    accountId,
                    state
                );
            }
            this.ability.update(this.authService.abilityRules);

            let action = route.data?.action || 'view';
            let subject = route.data.subject;
            let can = this.ability.can(action, subject);
            if (!can) return this.router.parseUrl('accPlaceholder/events/list');
            return can;
        } else {
            return true;
        }
    }
}
