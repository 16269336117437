import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { AuthService } from './auth.service';
import type { User } from '../../services/user.service';
import { ConsentModal } from '../consent/consent.modal.component';

@Injectable()
export class VerifyGuard implements CanActivate {
    authService;
    router;
    modalService;

    constructor(
        authService: AuthService,
        router: Router,
        modalService: NgbModal
    ) {
        this.authService = authService;
        this.router = router;
        this.modalService = modalService;
    }

    canActivate() {
        const self = this;
        return self.authService.getCurrentUser().then((user) => {
            if (user && user.verified) {
                //Check if user consented to policies
                this.checkConsent(user);
                return true;
            } else if (user) {
                return self.router.parseUrl('/authentication/email-request');
            } else {
                return self.authService.logout();
            }
        });
    }

    checkConsent(user: User) {
        const self = this;
        const policyUpdated = window['NoKeyConfig'].policyUpdated;
        const privacyDate = new Date(policyUpdated.privacy);
        const termsDate = new Date(policyUpdated.terms);
        const disclaimerDate = new Date(policyUpdated.disclaimer);
        if (
            user.consent &&
            user.consent.privacy &&
            user.consent.terms &&
            user.consent.disclaimer &&
            new Date(user.consent.privacy) > privacyDate &&
            new Date(user.consent.terms) > termsDate &&
            new Date(user.consent.disclaimer) > disclaimerDate
        ) {
            //NOP
        } else {
            //Open privacy modal
            if(this.modalService.hasOpenModals()) return null;
            const modalRef = this.modalService.open(ConsentModal, {
                backdrop: 'static',
                keyboard: false,
            });
            modalRef.componentInstance.user = user;
            modalRef.result.catch(() => {});
        }
    }
}
