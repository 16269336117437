// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService } from './base.service';
import { Observable } from 'rxjs';

import type { RuleFrontend as Rule } from 'server/api/rule/rule';
export type {
    RuleFrontend as Rule,
    RuleSettingsFrontend as RuleSettings,
} from 'server/api/rule/rule';

export enum NodeType {
    user = 'User',
    door = 'Door',
    site = 'Site',
    userGroup = 'User Group',
    doorGroup = 'Door Group',
    siteGroup = 'Site Group',
}

export interface ScheduleOptions {
    startTime: number;
    endTime: number;
    config?: {
        accessLimit?: number;
        accessLimitEnabled?: boolean;
        accessWindow?: number;
        accessWindowEnabled?: boolean;
    };
}

@Injectable()
export class RuleService extends BaseService<Rule> {
    modelName = 'rules';

    constructor(injector: Injector) {
        super(injector);
    }

    batchUpdates(
        deleteIds: string[],
        save: Rule[],
        adhoc: boolean = false,
        scheduleOptions?: ScheduleOptions
    ): Observable<boolean> {
        return this.http.post<boolean>(`/api/${this.modelName}/batchUpdate`, {
            delete: deleteIds,
            save: save,
            adhoc,
            scheduleOptions,
        });
    }
}
