/* ----------------------------- Angular Imports ---------------------------- */
import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

/* ----------------------------- Module Imports ----------------------------- */
import { lastValueFrom, Observable } from 'rxjs';

/* ----------------------------- Service Imports ---------------------------- */
import { BaseService } from './base.service';

/* ------------------------------ Type Imports ------------------------------ */
import type { BaseQuery, ExportOptions } from './base.service';
import type { SiteFrontend as Site } from 'server/api/site/site';
export type { SiteFrontend as Site, DoorFrontend as Door } from 'server/api/site/site';

@Injectable()
export class SiteService extends BaseService<Site> {
    /* -------------------------------------------------------------------------- */
    /*                                 Properties                                 */
    /* -------------------------------------------------------------------------- */
    modelName = 'sites';

    /* -------------------------------------------------------------------------- */
    /*                               Post Functions                               */
    /* -------------------------------------------------------------------------- */
    exportDoorData(
        options: ExportOptions,
        query?: BaseQuery,
        allAccounts = false
    ): Observable<Blob> {
        let params: HttpParams;
        if (query) {
            params = this.buildParams(query);
        }
        let contentType = 'application/json';
        switch (options.format) {
            case 'csv':
                contentType = 'text/csv';
                break;
            case 'xlsx':
                contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
                break;

            default:
        }

        let headers = new HttpHeaders({
            'Content-Type': 'application/json',
            Accept: contentType,
        });

        if (allAccounts) {
            headers = headers.append('x-js-all-accounts', 'true');
        }
        return this.http.post<Blob>(`/api/${this.modelName}/doors/export`, options, {
            params,
            headers,
            responseType: 'blob' as 'json',
        });
    }

    /**
     * Move a site from the current account to another.
     *
     * @param siteId The _id of the site to be moved.
     * @param accountId The _id of the account to move the site to.
     * @returns A promise that resolves to the moved site.
     */
    public moveSite(siteId: string, accountId: string) {
        return lastValueFrom(
            this.http.post<Site>(`/api/${this.modelName}/moveSite`, { siteId, accountId })
        );
    }
}
