import { Component, OnInit, OnDestroy, Input, NgZone } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import { Subscription } from 'rxjs';
import { AuthService } from '../auth/auth.service';

@Component({
    selector: 'idle-modal',
    templateUrl: './idle.modal.html',
    styleUrls: ['./idle.modal.scss'],
})
export class IdleModal implements OnInit, OnDestroy {
    constructor(
        public activeModal: NgbActiveModal,
        private idleService: UserIdleService,
        private authService: AuthService,
        private router: Router,
        private ngZone: NgZone
    ) {}

    private timerStartSubscription!: Subscription;
    idle?: number;
    wait?: number;
    timeout?: number;
    ngOnInit() {
        this.wait = 0;
        this.idle = this.idleService.getConfigValue().idle;
        this.timeout = this.idleService.getConfigValue().timeout;
        this.timerStartSubscription =
            this.authService.idleTimerObservable.subscribe((value) => {
                console.log(`Value: ${value}`);
                this.wait = value;
            });
        console.log('Subscribed, ', this.timerStartSubscription);
    }

    ngOnDestroy() {
        console.log('unsubscribe');
        this.timerStartSubscription.unsubscribe();
    }
    ok() {}

    doLog() {
        console.debug(this);
    }

    cancel() {
        this.idleService.resetTimer();
        this.activeModal.close();
    }
}
