// @flow
import { Component } from '@angular/core';

import { Router } from '@angular/router';
import {
    AuthService,
    LoginOptions,
} from '../../../components/auth/auth.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TOTPModal } from '../../../components/totp/totp.modal.component';
import { LogoService } from '../../../components/logoManager/logoManager.service';

interface User {
    name: string;
    email: string;
    password: string;
}

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: [
        '../../../vendor/styles/pages/authentication.scss',
        './login.component.scss',
    ],
})
export class LoginComponent {
    user: User = {
        name: '',
        email: '',
        password: '',
    };
    errors = { login: undefined };
    submitted = false;
    loading = false;
    cognitoTenants: string[];
    authenticationProvider: string;

    //static parameters = [AuthService, Router, LogoService];
    constructor(
        private authService: AuthService,
        private router: Router,
        public logoService: LogoService,
        private modalService: NgbModal
    ) {
        this.cognitoTenants = window['NoKeyConfig'].cognitoTenants || []
        this.authenticationProvider = window['NoKeyConfig'].authenticationProvider;
    }

    login(form, tenant?) {
        const self = this;
        this.loading = true;
        if (form.invalid && !tenant) return;

        let options: LoginOptions;
        if (this.authenticationProvider === 'local') {
            options = {
                type: 'Local',
                email: this.user.email.trim(),
                password: this.user.password,
            };
        } else {
            // Cognito
            options = {
                type: 'SRP',
                email: this.user.email.trim(),
                password: this.user.password,
            };
            if (tenant) {
                options = {
                    type: 'CustomFederated',
                    provider: tenant,
                };
            }
        }
        return this.authService
            .login(options)
            .then((data) => {
                if (this.authenticationProvider === 'cognito') {
                    return this.router.navigate(['accPlaceholder/home']);
                }

                if (data) {
                    if (!data.verified) {
                        return this.router.navigate([
                            'authentication/email-request',
                        ]);
                    } else if (data.mfaRequired && data.mfa) {
                        const modalRef = this.modalService.open(TOTPModal, {
                            backdrop: 'static',
                        });
                        modalRef.componentInstance.mfa = data.mfa;
                        modalRef.result
                            .then(() => {
                                this.router.navigate(['accPlaceholder/home']);
                            })
                            .catch(() => null);
                    } else {
                        this.router.navigate(['accPlaceholder/home']);
                    }
                } else {
                    // TODO: Saved state redirect <09-10-20, Liaan> //
                    this.router.navigate(['accPlaceholder/home']);
                }
                // Logged in, redirect to home
            })
            .catch((err) => {
                console.log('Err in authService');
                console.error(err);
                if (err) {
                    this.errors.login =
                        err.error?.message || err.message || err.error || err;
                }
            })
            .finally(() => (this.loading = false));
    }
}
