import { Injectable, NgZone } from '@angular/core';
import { Amplify, Auth, Hub } from 'aws-amplify';

//** Constants */
import { LoginOptions } from 'client/components/auth/auth.service';
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { PasswordResetComponent } from 'client/app/authentication/password-reset/password-reset.component';
import { TOTPModal } from '../totp/totp.modal.component';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class CognitoService {
    public Auth = Auth;
    constructor(private modalService: NgbModal, private router: Router, private zone: NgZone) {
        Amplify.configure({
            Auth: window['NoKeyConfig'].cognito,
        });

        Auth.currentAuthenticatedUser()
            .then((userData) => {})
            .catch(() => console.warn('Not signed in'));
        Hub.listen('auth', ({ payload: { event, data } }) => {
            switch (event) {
                case 'signIn':
                    // Don't redirect on signin via cognito...
                    // router.navigate(['/accPlaceholder/home']);
                    break;
                case 'signOut':
                    router.navigate(['/authentication/login']);
                    break;
                case 'customOAuthState':
                    console.warn('customOAuthState');
            }
        });
    }

    async signIn(options: LoginOptions) {
        let data;
        switch (options.type) {
            case 'CustomFederated':
                data = await Auth.federatedSignIn({
                    customProvider: options.provider,
                });
                break;
            case 'Federated':
                data = await Auth.federatedSignIn({
                    provider: options.provider as CognitoHostedUIIdentityProvider,
                });
                break;
            case 'SRP':
            default:
                data = await Auth.signIn({
                    username: options.email,
                    password: options.password,
                });
                break;
        }
        if (data && data.challengeName) {
            switch (data.challengeName) {
                case 'NEW_PASSWORD_REQUIRED':
                    await this.newPassword(data);

                    break;
                case 'MFA_SETUP':
                    await this.mfa(data);
                    return true;
                    break;
                case 'SOFTWARE_TOKEN_MFA':
                    await this.mfa(data, false);
                    return true;
                    break;
                case 'SMS_MFA':
                case 'SELECT_MFA_TYPE':
                case 'PASSWORD_VERIFIER':
                case 'CUSTOM_CHALLENGE':
                case 'DEVICE_SRP_AUTH':
                case 'DEVICE_PASSWORD_VERIFIER':
                case 'ADMIN_NO_SRP_AUTH':
                default:
                    console.error('AUTH challenge not supported : ', data.challengeName);
                    throw new Error(`AUTH challenge not supported : ${data.challengeName}`);
                    break;
            }
        }

        if (
            data?.preferredMFA === 'NOMFA' &&
            options.type !== 'Federated' &&
            options.type !== 'CustomFederated'
        ) {
            await this.mfa(data);
            return true;
        }
    }

    newPassword(user) {
        return new Promise((res, rej) => {
            this.zone.run(() => {
                const modalRef = this.modalService.open(PasswordResetComponent);
                modalRef.componentInstance.isModal = true;
                modalRef.componentInstance.cognito = true;
                modalRef.componentInstance.cognitoUser = user;

                res(modalRef.result);
            });
        });
    }

    mfa(user, setup = true) {
        return new Promise((res, rej) => {
            this.zone.run(() => {
                const modalRef = this.modalService.open(TOTPModal);
                modalRef.componentInstance.cognito = true;
                modalRef.componentInstance.cognitoUser = user;
                if (setup) {
                    modalRef.componentInstance.mfa = { enrolled: false };
                } else {
                    modalRef.componentInstance.mfa = { enrolled: true };
                }
                modalRef.result.then(res).catch(rej);
            });
        });
    }
}
