import { NgModule } from '@angular/core';
import { LoginGuard } from './login-guard.service';
import { VerifyGuard } from './verify-guard.service';
import { AbilityGuard } from './ability-guard.service';
import { ConsentModule } from '../consent/consent.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CognitoInterceptor } from './cognito.interceptor';
import { IdleModule } from '../idle/idle.module';
import { UserIdleModule } from 'angular-user-idle';

@NgModule({
    providers: [
        LoginGuard,
        VerifyGuard,
        AbilityGuard,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: CognitoInterceptor,
            multi: true,
        },
        IdleModule,
    ],
    imports: [
        ConsentModule,
        UserIdleModule.forRoot({ idle: 60 * 5, timeout: 60 }),
    ],
    entryComponents: [],
})
export class AuthModule {}
