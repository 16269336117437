import { AbilityBuilder, Ability, AbilityClass } from '@casl/ability';

//Setup typescript
//manage -> Wildcard for all actions
export type Actions = 'create' | 'view' | 'update' | 'delete' | 'recertify' | 'manage' | 'install';
//all -> Wildcard for all subjects
export type Subjects =
    | 'Sites'
    | 'Controllers'
    | 'Access Control'
    | 'Access Events'
    | 'Audit Logs'
    | 'Users'
    | 'appUser'
    | 'reportingUser'
    | 'accessManager'
    | 'accountManager'
    | 'installer'
    | 'admin'
    | 'iButton'
    | 'idleTime'
    | 'Accounts'
    | 'MFA'
    | 'RemoteAuth'
    | 'Contacts'
    | 'Dashboards'
    | 'Integrations'
    | 'Tenants'
    | 'all';
export type AppAbility = Ability<[Actions, Subjects]>;
export const AppAbility = Ability as AbilityClass<AppAbility>;

export function defineAbilityForAccount(user, account) {
    const { can, cannot, rules } = new AbilityBuilder<AppAbility>(AppAbility);
    const role = account ? account.role : user.role;

    if(user.hyper){
        can('manage', 'all');
        if (!window['NoKeyConfig']?.recertification?.enabled) {
            cannot('recertify', 'Users');
        }
        return rules;
    }
    //This is hard coded for now but can be moved to db in future
    switch (role) {
        case 'admin': {
            can('manage', 'Accounts'); // All actions for accounts
            can('manage', 'Integrations'); // All actions for accounts

            can('manage', 'idleTime');
            can('manage', 'iButton');
            //USER Administrators
            can('manage', 'admin');
            //USER Account Managers
            can('manage', 'accountManager');
            //USER Installers
            can('manage', 'installer');
            //USER Access Managers
            can('manage', 'accessManager');
            //USER Reporting Users
            can('manage', 'reportingUser');
            //USER App Users
            can('manage', 'appUser');

            can('manage', 'Users');

            can('manage', 'Sites'); // All actions for things in current account
            can('manage', 'Access Events');
            can('manage', 'Audit Logs');
            can('manage', 'Access Control');
            can('manage', 'Controllers');
            can('manage', 'MFA');
            can('manage', 'RemoteAuth');
            can('manage', 'Contacts');
            can('manage', 'Dashboards');
            if (!window['NoKeyConfig']?.recertification?.enabled) {
                cannot('recertify', 'Users');
            }
            break;
        }
        case 'accountManager': {
            can('update', 'Accounts'); // All actions for accounts
            can('view', 'Accounts'); // All actions for accounts

            can('manage', 'idleTime');
            can('manage', 'iButton');
            //USER Access Managers
            can('manage', 'accessManager');
            //USER Installers
            can('manage', 'installer');
            //USER Reporting Users
            can('manage', 'reportingUser');
            //USER App Users
            can('manage', 'appUser');

            can('view', 'Users');
            can('create', 'Users');
            can('update', 'Users');
            if (window['NoKeyConfig']?.recertification?.enabled) can('recertify', 'Users');

            can('manage', 'Sites'); // All actions for things in current account
            can('manage', 'Access Events');
            can('manage', 'Audit Logs');
            can('manage', 'Access Control');
            can('manage', 'Controllers');
            can('manage', 'MFA');
            can('manage', 'RemoteAuth');
            can('manage', 'Contacts');
            can('manage', 'Dashboards');
            break;
        }
        case 'installer': {
            can('view', 'Accounts'); // All actions for accounts
            can('view', 'Users');

            can('view', 'Sites'); 
            can('install', 'Sites'); 
            can('create', 'Sites');

            can('manage', 'Access Events');
            can('manage', 'Audit Logs');
            can('manage', 'Controllers');
            can('view', 'Contacts');
            break;
        }
        case 'accessManager': {
            can('view', 'Accounts'); // All actions for accounts

            //USER App Users
            can('manage', 'appUser');

            can('view', 'Users');
            can('create', 'Users');
            can('update', 'Users');
            if (window['NoKeyConfig']?.recertification?.enabled) can('recertify', 'Users');

            can('view', 'Sites'); // All actions for things in current account
            can('manage', 'Access Events');
            can('manage', 'Access Control');
            can('view', 'Controllers');
            can('manage', 'RemoteAuth');
            can('manage', 'Contacts');
            can('manage', 'Dashboards');
            break;
        }
        case 'reportingUser': {
            can('view', 'Accounts'); // All actions for accounts

            can('view', 'Users');

            can('view', 'Sites'); // All actions for things in current account
            can('view', 'Access Events');
            can('view', 'Controllers');
            break;
        }
        case 'appUser': {
            can('view', 'Access Events');
            can('view', 'Users');
            can('view', 'Accounts');
            break;
        }
    }

    return rules;
}

//export function defineAbilityForUser(user) {
//const { can, cannot, build } = new AbilityBuilder<AppAbility>();
//const role = user.role;

////This is hard coded for now but can be moved to db in future
//if (role == 'admin') {
//can('manage', 'User', ['name', 'email', 'accounts']); // All actions for users
////can('manage', 'Thing', ['info']); // All actions for things in current account
//can('manage', 'Account'); // All actions for accounts
//} else {
//can('read', 'User', { _id: user._id }); // read-only access to this user
//can('read', 'Thing'); // read-only access to everything
//can('read', 'Account'); // read-only access to everything
//}

//cannot('delete', 'User'); //Users cannot be deleted

//return build();
//}
