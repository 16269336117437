// @flow
import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
import { Observable } from 'rxjs';

import type { TaskFrontend as Task } from 'server/api/task/task';
export type { TaskFrontend as Task } from 'server/api/task/task';

@Injectable()
export class TaskService extends BaseService<Task> {
    modelName = 'tasks';
    constructor(injector: Injector) {
        super(injector);
    }

    actionTask(taskId: string, grant: boolean): Observable<any> {
        return this.http.post<any>(`/api/tasks/${taskId}/actionRemoteAuth`, {
            grant,
        });
    }
}
