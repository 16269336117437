// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService } from './base.service';

import type { ScheduleFrontend as Schedule } from 'server/api/schedule/schedule';
export type { ScheduleFrontend as Schedule } from 'server/api/schedule/schedule';

@Injectable()
export class ScheduleService extends BaseService<Schedule> {
    modelName = 'schedules';

    constructor(injector: Injector) {
        super(injector);
    }
}
