/* ----------------------------- Angular Imports ---------------------------- */
import { Injectable, Injector } from '@angular/core';
import { HttpParams } from '@angular/common/http';

/* ----------------------------- Module Imports ----------------------------- */
import { firstValueFrom, lastValueFrom, Observable } from 'rxjs';

/* ----------------------------- Service Imports ---------------------------- */
import { BaseService } from './base.service';
import { CognitoService } from 'client/components/auth/cognito.service';

/* ------------------------------ Type Imports ------------------------------ */
import type { BaseQuery } from './base.service';
import type { UserFrontend as User } from 'server/api/user/user';
export type { UserFrontend as User, DeviceFrontend as Device } from 'server/api/user/user';

export interface PassCredentials {
    password: string;
    userId: string;
    token: string;
}

export interface VerifyCredentials {
    userId: string;
    token: string;
}

@Injectable()
export class UserService extends BaseService<User> {
    modelName = 'users';
    authenticationProvider: string;
    constructor(injector: Injector, private cognitoService: CognitoService) {
        super(injector);
        this.authenticationProvider = window['NoKeyConfig'].authenticationProvider;
    }

    changePassword(userId: string, oldPassword: string, newPassword: string): Observable<void> {
        return this.http.put<void>(`/api/users/${userId}/password`, {
            oldPassword,
            newPassword,
        });
    }

    /**
     * Imports a list of users into the current account.
     *
     * @param users The list of users with their desired roles in the current account.
     * @returns A promise that resolves when the users have been imported.
     */
    public importUsers(users: { _id: string; role: string }[]) {
        return lastValueFrom(this.http.patch<boolean>(`/api/users/importUsers`, { users }));
    }

    /**
     * Imports a list of user groups into the current account.
     *
     * @param fromAccount The account to import the user groups from.
     * @param groups The list of groups to import.
     * @returns A promise that resolves when the user groups have been imported.
     */
    public importGroups(fromAccount: string, groups: string[]) {
        return lastValueFrom(
            this.http.patch<boolean>(`/api/users/importGroups`, { fromAccount, groups })
        );
    }

    get(user: User = { id: 'me' }): Observable<User> {
        return this.http.get<User>(`/api/users/${user.id || user._id}`);
    }

    requestVerificationEmail(userId: string): Observable<any> {
        return this.http.get<any>(`/api/users/${userId}/verify`);
    }

    requestForgotPasswordEmail(email: string): Promise<any> {
        if (this.authenticationProvider === 'cognito') {
            return this.cognitoService.Auth.forgotPassword(email);
        } else {
            const params = new HttpParams().set('email', email);
            return firstValueFrom(this.http.get<any>(`/api/users/forgot`, { params: params }));
        }
    }

    resetPassword(credentials: PassCredentials): Promise<any> {
        if (this.authenticationProvider === 'cognito') {
            return this.cognitoService.Auth.forgotPasswordSubmit(
                credentials.userId,
                credentials.token,
                credentials.password
            );
        } else {
            return firstValueFrom(
                this.http.put<any>(`/api/users/${credentials.userId}/forgot/${credentials.token}`, {
                    password: credentials.password,
                })
            );
        }
    }

    verifyEmail(credentials: VerifyCredentials): Observable<any> {
        return this.http.get<any>(`/api/users/${credentials.userId}/verify/${credentials.token}`);
    }

    /**
     * Gets a list of users that can be imported into the current account from another account.
     *
     * @param account The _id of the account to import users from.
     * @param query The query to use when getting the imporable users.
     * @returns A promise that resolves to the list of importable users.
     */
    public getImportable(account: string, query?: BaseQuery) {
        let params: HttpParams;
        if (query) params = this.buildParams(query);
        if (account) {
            if (!params) params = new HttpParams();
            params = params.set('fromAccount', account);
        }
        return lastValueFrom(
            this.http.get<User[]>(`/api/${this.modelName}/importableUsers`, { params: params })
        );
    }

    getMFASetup(): Observable<any> {
        return this.http.get<any>(`/api/users/mfasetup`);
    }

    MFASetup(code: string): Observable<any> {
        return this.http.post<any>(`/api/users/mfasetup`, { code });
    }

    disableMFA(): Observable<any> {
        return this.http.post<any>(`/api/users/disablemfa`, {});
    }

    enableMFA(): Observable<any> {
        return this.http.post<any>(`/api/users/enablemfa`, {});
    }

    resetMFA(userId: string): Observable<any> {
        return this.http.post<any>(`/api/users/${userId}/mfaReset`, {});
    }

    resendVerficationToken(userId: string): Observable<any> {
        return this.http.post<any>(`/api/users/${userId}/resendVerificationToken`, {});
    }

    addUserAccounts(userId: string, accountIds: string[]): Observable<User> {
        return this.http.patch<User>(`/api/users/${userId}/addAccounts`, {
            accountIds,
        });
    }

    removeFromAccount(userId: string): Observable<User> {
        return this.http.patch<User>(`/api/users/${userId}/removeAccount`, {});
    }

    saveMe(user: User): Observable<User> {
        return this.http.patch<User>(`/api/${this.modelName}/me`, user);
    }

    /**
     * Gets a list of users that can be recertified by the current user.
     *
     * @param query The query to use when getting the list of recertifyable users.
     * @returns A promise that resolves to the list of users.
     */
    public getRecertifyable(query?: BaseQuery) {
        let params: HttpParams;
        if (query) params = this.buildParams(query);
        return lastValueFrom(this.http.get<User[]>(`/api/users/recertifyable`, { params }));
    }

    /**
     * Counts users that can be recertified by the current user.
     *
     * @param query The query to use when getting the count of recertifyable users.
     * @returns A promise that resolves to the count of users.
     */
    public countRecertifyable(query?: BaseQuery) {
        let params: HttpParams;
        if (query) params = this.buildParams(query);
        return lastValueFrom(this.http.get<number>(`/api/users/recertifyable/count`, { params }));
    }

    /**
     * Recertifies a user.
     *
     * @param id The _id of the user to recertify.
     * @returns A promise that resolves to the updated user.
     */
    public recertify(id: string) {
        return lastValueFrom(this.http.patch<User>(`/api/users/recertify/${id}`, {}));
    }
}
