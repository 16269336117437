<div class="modal-header" *ngIf="activeModal">
	<h3 class="modal-title"><i class="fas fa-fw fa-key"></i> Idle Logout</h3>
</div>
<div [class.modal-body]="activeModal">
    <p>You have been idle for more than {{idle}} seconds.</p>
    <p>You will be logged out automatically after {{timeout - wait}} seconds, or press "Cancel" to stay logged in.</p>
    <ngb-progressbar [value]="timeout - wait" [max]="timeout"></ngb-progressbar>
</div>
<div class="modal-footer" *ngIf="activeModal">
	<button (click)="cancel()" class="btn btn-warning">Cancel</button>
</div>