// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService } from './base.service';

import type { ContactFrontend as Contact } from 'server/api/contact/contact';
export type {
    ContactFrontend as Contact,
    ContactRefFrontend as ContactRef,
} from 'server/api/contact/contact';

@Injectable()
export class ContactService extends BaseService<Contact> {
    modelName = 'contacts';
    constructor(injector: Injector) {
        super(injector);
    }
}
