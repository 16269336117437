import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TOTPModal } from './totp.modal.component';
import { ClipboardModule } from 'ngx-clipboard';

@NgModule({
    declarations: [TOTPModal],
    imports: [CommonModule, NgSelectModule, FormsModule, NgbModule, ClipboardModule],
    exports: [TOTPModal],
    entryComponents: [TOTPModal],
})
export class TOTPModule {}
