import { Injectable, Injector } from '@angular/core';
//import { SocketService } from '../components/socket/socket.service';
//import { HttpClient } from '@angular/common/http';
import type { ContactRef } from './contact.service';

import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import type { AccountFrontend as Account } from 'server/api/account/account';
export type {
    AccountFrontend as Account,
    AccountStubFrontend as AccountStub,
} from 'server/api/account/account';

@Injectable()
export class AccountService extends BaseService<Account> {
    modelName = 'accounts';
    constructor(injector: Injector) {
        super(injector);
    }

    enforceMFA(accountId: string, enable: boolean): Observable<any> {
        return this.http.post<any>(`/api/accounts/${accountId}/enforceMFA`, {
            enforce: enable,
        });
    }
}
