import { Injectable, Injector } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';

import { BaseService } from './base.service';
import type { UnitFrontend as Unit } from 'server/api/unit/unit';
export type { UnitFrontend as Unit } from 'server/api/unit/unit';
export type { HeartbeatFrontend as Heartbeat } from 'server/api/heartbeat/heartbeat';
import { HttpHeaders } from '@angular/common/http';

@Injectable()
export class UnitService extends BaseService<Unit> {
    modelName = 'units';

    constructor(injector: Injector) {
        super(injector);
    }

    moveUnits(unitIds: string[], toAccount: string): Observable<any> {
        return this.http.post<any>(`/api/units/moveUnits`, {
            unitIds,
            toAccount,
        });
    }

    requestHB(unitId: string, allAccounts = false): Promise<boolean> {
        let headers;
        if (allAccounts) {
            headers = new HttpHeaders({
                'x-js-all-accounts': 'true',
            });
        }
        return lastValueFrom(
            this.http.post<boolean>(
                `/api/${this.modelName}/${unitId}/requestHB`,
                {},
                {
                    headers,
                }
            )
        );
    }
}
