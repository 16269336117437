// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService } from './base.service';

import type { TenantFrontend as Tenant } from 'server/api/tenant/tenant';
import { Observable } from 'rxjs';
export type { TenantFrontend as Tenant } from 'server/api/tenant/tenant';

@Injectable()
export class TenantService extends BaseService<Tenant> {
    modelName = 'tenants';
    constructor(injector: Injector) {
        super(injector);
    }

    refresh(): Observable<Tenant[]> {
        return this.http.post<Tenant[]>(`/api/${this.modelName}/refresh`, {});
    }
}
