import { NgModule, Type } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
//import { NotFoundComponent } from './not-found/not-found.component';
import { LoginGuard } from '../components/auth/login-guard.service';
import { VerifyGuard } from '../components/auth/verify-guard.service';
import type { DashboardModule } from '../app/dashboard/dashboard.module';
import type { UsersModule } from '../app/users/users.module';
import type { RecertifyModule } from './recertify/recertify.module';
import type { UnitsModule } from '../app/units/units.module';
import type { EventsModule } from '../app/events/events.module';
import type { SitesModule } from '../app/sites/sites.module';
import type { RulesModule } from '../app/rules/rules.module';
import type { ContactsModule } from '../app/contacts/contacts.module';
import type { AuthenticationModule } from '../app/authentication/authentication.module';
import type { AccountModule } from '../app/account/account.module';
import { AccountResolver } from '../components/auth/account.resolver';

// *******************************************************************************
// Layouts

import { Layout2Component } from './layout/layout-2/layout-2.component';
import { LayoutBlankComponent } from './layout/layout-blank/layout-blank.component';
import { AbilityGuard } from 'client/components/auth/ability-guard.service';
import type { TenantModule } from './tenant/tenant.module';

// *******************************************************************************
// Routes

const routes: Routes = [
    {
        path: 'dashboard',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard, AbilityGuard],
        data: { subject: 'Dashboards' },
        loadChildren: (): Promise<Type<DashboardModule>> => {
            return import('./dashboard/dashboard.module').then((m) => m.DashboardModule);
        },
    },
    {
        path: 'users',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<Type<UsersModule>> => {
            return import('./users/users.module').then((m) => m.UsersModule);
        },
    },
    {
        path: 'recertify',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard],
        loadChildren: (): Promise<Type<RecertifyModule>> => {
            return import('./recertify/recertify.module').then((m) => m.RecertifyModule);
        },
    },
    {
        path: 'rules',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard, AbilityGuard],
        data: { subject: 'Access Control' },
        loadChildren: (): Promise<Type<RulesModule>> => {
            return import('./rules/rules.module').then((m) => m.RulesModule);
        },
    },
    {
        path: 'sites',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard, AbilityGuard],
        data: { subject: 'Sites' },
        loadChildren: (): Promise<Type<SitesModule>> => {
            return import('./sites/sites.module').then((m) => m.SitesModule);
        },
    },
    {
        path: 'units',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard, AbilityGuard],
        data: { subject: 'Controllers' },
        loadChildren: (): Promise<Type<UnitsModule>> => {
            return import('./units/units.module').then((m) => m.UnitsModule);
        },
    },
    {
        path: 'events',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard, AbilityGuard],
        data: { subject: 'Access Events' },
        loadChildren: (): Promise<Type<EventsModule>> => {
            return import('./events/events.module').then((m) => m.EventsModule);
        },
    },
    {
        path: 'contacts',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard, AbilityGuard],
        data: { subject: 'Contacts' },
        loadChildren: (): Promise<Type<ContactsModule>> => {
            return import('./contacts/contacts.module').then((m) => m.ContactsModule);
        },
    },
    {
        path: 'account',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard, AbilityGuard],
        data: { subject: 'Accounts' },
        loadChildren: (): Promise<Type<AccountModule>> => {
            return import('./account/account.module').then((m) => m.AccountModule);
        },
    },
    {
        path: 'tenant',
        component: Layout2Component,
        canActivate: [LoginGuard, VerifyGuard, AbilityGuard],
        data: { subject: 'Tenants' },
        loadChildren: (): Promise<Type<TenantModule>> => {
            return import('./tenant/tenant.module').then((m) => m.TenantModule);
        },
    },
    {
        path: 'authentication',
        component: LayoutBlankComponent,
        loadChildren: (): Promise<Type<AuthenticationModule>> => {
            return import('./authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            );
        },
    },
    { path: '', redirectTo: '/sites', pathMatch: 'full' },
];

// *******************************************************************************
// Base Routes

const baseRoutes: Routes = [
    {
        path: ':accountId',
        resolve: {
            currentAccount: AccountResolver,
        },
        children: routes,
    },
    {
        path: 'authentication',
        component: LayoutBlankComponent,
        loadChildren: (): Promise<Type<AuthenticationModule>> => {
            return import('./authentication/authentication.module').then(
                (m) => m.AuthenticationModule
            );
        },
    },

    { path: '', redirectTo: 'null/sites', pathMatch: 'full' },
    //Wildcard redirect to hom
    { path: '**', redirectTo: 'null/sites', pathMatch: 'full' },

    // 404 Not Found page
    //{ path: '**', component: NotFoundComponent },
];
// *******************************************************************************
//

@NgModule({
    imports: [RouterModule.forRoot(baseRoutes)],
    exports: [RouterModule],
})
export class AppRoutingModule {}
