import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { AppService } from '../../app.service';
import { UserService } from '../../../services/user.service';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import { NgForm } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LogoService } from '../../../components/logoManager/logoManager.service';
import zxcvbn from 'zxcvbn';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CognitoService } from 'client/components/auth/cognito.service';

@Component({
    selector: 'app-password-reset',
    templateUrl: './password-reset.component.html',
    styleUrls: ['../../../vendor/styles/pages/authentication.scss'],
})
export class PasswordResetComponent implements OnInit {
    @ViewChild('passwordResetForm') passwordResetForm: NgForm;
    hasToken = false;
    token: string;
    userId: string;
    linkSent = false;
    @Input() isModal = false;
    @Input() cognito = false;
    @Input() cognitoUser;

    credentials = {
        email: '',
        userId: '',
        password: '',
        token: '',
        confirmPassword: '',
    };

    feedback: {
        warning?: string;
        suggestions?: string[];
    };
    valid = false;

    constructor(
        public activeModal: NgbActiveModal,
        private appService: AppService,
        private userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        private toastrService: ToastrService,
        public logoService: LogoService,
        private cognitoService: CognitoService
    ) {
        this.appService.pageTitle = 'Password Reset';
    }

    ngOnInit() {
        this.route.paramMap.subscribe(
            (params: ParamMap) => {
                if (params.has('userId') && params.has('token')) {
                    this.credentials.userId = params.get('userId');
                    this.credentials.token = params.get('token');
                    this.hasToken = true;
                }
            },
            (err) => console.error(err)
        );
    }

    requestReset() {
        this.userService
            .requestForgotPasswordEmail(this.credentials.email)
            .then((val) => {
                this.linkSent = true;
            })
            .catch((err) => {
                console.error(err);
            });
    }

    resetPassword() {
        if (this.cognito) {
            this.cognitoService.Auth.completeNewPassword(
                this.cognitoUser,
                this.credentials.password,
            )
                .then((data) => {
                    this.toastrService.success('Password reset.');
                    if(this.isModal && this.activeModal){
                        this.activeModal.close();
                    }
                    this.router.navigate(['/accPlaceholder/home']);
                })
                .catch((err) => {
                    console.error(err);
                });
        } else {
            this.userService
                .resetPassword(this.credentials)
                .then((val) => {
                    this.toastrService.success('Password reset.');
                    this.router.navigate(['/authentication/login']);
                })
                .catch((err) => {
                    this.toastrService.error('Password reset failed.');
                    this.router.navigate(['/authentication/password-reset']);

                    console.error(err);
                });
        }
    }

    doLog() {
        console.debug(this);
    }

    onPasswordChange(event) {
        const result = zxcvbn(this.credentials.password);

        if (result && result.score < 2) {
            this.feedback = result.feedback;
            this.valid = false;
        } else {
            this.feedback = undefined;
            this.valid = true;
        }
    }
}
