//import Primus from 'primus';
const Primus = require('primus');
import { Injectable, Injector } from '@angular/core';
import { noop, find, remove, mergeWith, isArray } from 'lodash';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AuthService } from '../auth/auth.service';

export interface SyncEvent {
    event: string;
    item: any;
}

@Injectable({
    providedIn: 'root',
})
export class SocketService {
    private primus;
    private listenerMap: Record<string, any>;
    private rooms: Record<string, any> = {};
    auth;
    private unsubscribe = new Subject();

    constructor(private injector: Injector) {
        const self = this;
        setTimeout(() => {
            self.auth = injector.get(AuthService);
        });
        const primus = new Primus({
            manual: true,
            reconnect: {
                retries: 100,
            },
        });

        primus.on('open', function open() {
            // TODO
        });

        primus.on('data', function (data) {
            //console.debug('Got Primus DATA', data);
            // TODO
        });

        primus.on('outgoing::url', (url) => {
            url.query += `${url.query.length > 0 ? '&' : ''
                }appVersion=${self.auth.getVersion()}`;
        });

        primus.on('error', (err) => {
            console.error('Primus socket error: ', err);
        });
        if (process.env.NODE_ENV === 'development') {
            primus.on('data', function message(data) {});
        }

        primus.on('info', (data) => {});

        //setTimeout(() => {
        //primus.end().open();
        //});
        self.primus = primus;
        self.listenerMap = {};
    }

    /**
     * Creates an observable that emits all events for a model.  An optional array can be passed that will be synced automatically using the events.  An optional room can be passed that will only listen to events in the room.
     *
     * @param modelName This is the name of the model to sync
     * @param array Optional array used to automatically sync events
     * @param room Optional room to register to
     * @param options Options
     * @returns An Observable with the event type and item
     */
    syncUpdates(
        modelName: string,
        array?: any[],
        room?: string,
        options: { addCreated?: boolean } = { addCreated: true }
    ): Observable<SyncEvent> {
        const self = this;
        if (self.primus.readyState == 2) {
            self.primus.end().open();
        }
        const obs = new Observable<SyncEvent>((observer) => {
            const createFunction = (item) => {
                if (array && options.addCreated) {
                    array.push(item);
                }
                observer.next({
                    event: 'create',
                    item,
                });
            };

            const updateFunction = (item) => {
                //  TODO: Implement patching updates <11-05-20, Liaan> //
                if (array) {
                    const oldItem = find(array, ['_id', item._id]);
                    if (oldItem) {
                        //This could possibly be replaced by JSON fast patch
                        mergeWith(oldItem, item, (objValue, srcValue) => {
                            if (isArray(objValue)) {
                                return srcValue;
                            }
                        });
                    }
                }
                observer.next({
                    event: 'update',
                    item,
                });
            };

            const saveFunction = (item) => {
                let event = 'create';
                if (array) {
                    const oldItem = find(array, ['_id', item._id]);
                    if (oldItem) {
                        event = 'update';
                        //This could possibly be replaced by JSON fast patch
                        mergeWith(oldItem, item, (objValue, srcValue) => {
                            if (isArray(objValue)) {
                                return srcValue;
                            }
                        });
                    } else if (options.addCreated) {
                        array.push(item);
                    }
                }
                observer.next({
                    event: event,
                    item,
                });
            };

            const deleteFunction = (item) => {
                
                if (array) {
                    remove(array, { _id: item._id });
                }
                observer.next({
                    event: 'delete',
                    item,
                });
            };

            if (room) {
                self.joinRoom(room);
            }
            
            self.primus.on(`${modelName}:save`, saveFunction);
            self.primus.on(`${modelName}:remove`, deleteFunction);
            //  TODO: Change save event to create when patch is implemented on sockets <12-05-20, Liaan> //
            //self.primus.on(`${modelName}:create`, createFunction);
            //self.primus.on(`${modelName}:update`, updateFunction);
            //self.primus.on(`${modelName}:delete`, updateFunction);
            return {
                unsubscribe() {
                    //  TODO: Un-sync updates and leave room<11-05-20, Liaan> //
                    //self.primus.removeListener(
                    //`${modelName}:save`,
                    //createFunction
                    //);
                    //self.primus.removeListener(
                    //`${modelName}:update`,
                    //updateFunction
                    //);
                    self.primus.removeListener(
                        `${modelName}:save`,
                        saveFunction
                    );
                    self.primus.removeListener(
                        `${modelName}:remove`,
                        deleteFunction
                    );
                    if (room) {
                        self.leaveRoom(room);
                    }
                },
            };
        });
        return obs.pipe(takeUntil(self.unsubscribe));
    }

    /**
     * Register listeners to sync an array with updates on a model
     *
     * Takes the array we want to sync, the model name that socket updates are sent from,
     * and an optional callback function after new items are updated.
     *
     * @param {String} modelName
     * @param {Array} array
     * @param {Function} cb
     */
    syncUpdatesOld(modelName, array, cb = noop, context) {
        /**
         * Syncs item creation/updates on 'model:save'
         */
        const saveFunc = (item) => {
            const oldItem = find(array, { _id: item._id });
            const index = array.indexOf(oldItem);
            let event = 'created';

            // replace oldItem if it exists
            // otherwise just add item to the collection
            if (oldItem) {
                array.splice(index, 1, item);
                event = 'updated';
            } else {
                array.push(item);
            }

            cb(event, item, array);
        };
        this.primus.on(`${modelName}:save`, saveFunc);

        /**
         * Syncs removed items on 'model:remove'
         */
        const removeFunc = (item) => {
            remove(array, { _id: item._id });
            cb('deleted', item, array);
        };
        this.primus.on(`${modelName}:remove`, removeFunc);

        if (typeof context !== 'undefined') {
            const listeners = this.listenerMap[context];
            if (listeners) {
                this.primus.removeListener(modelName + ':save', listeners.save);
                this.primus.removeListener(
                    modelName + ':remove',
                    listeners.remove
                );
            }
            this.listenerMap[context] = { save: saveFunc, remove: removeFunc };
        }
    }

    /**
     * Removes listeners for a models updates on the socket
     *
     * @param modelName
     * @param context Used to only un-sync the relevant connections
     */
    unsyncUpdates(modelName, context) {
        if (typeof context !== 'undefined') {
            const listeners = this.listenerMap[context];
            if (listeners) {
                this.primus.removeListener(modelName + ':save', listeners.save);
                this.primus.removeListener(
                    modelName + ':remove',
                    listeners.remove
                );
                delete this.listenerMap[context];
                return;
            }
        }
        this.listenerMap = {};
        this.primus.removeAllListeners(`${modelName}:save`);
        this.primus.removeAllListeners(`${modelName}:remove`);
    }

    reAuth() {
        // This implicitly causes re-authentication due to the open handler injecting query parameter
        this.primus.end().open();
    }

    /**
     * Disconnects the socket.
     */
    disconnect() {
        this.unsubscribe.next(null);
        this.unsubscribe.complete();
        this.leaveAll();
        this.primus.end();
    }

    /**
     * Joins a room
     *
     * @param roomName The room to join
     */
    joinRoom(roomName) {
        this.rooms[roomName] = this.rooms[roomName]
            ? this.rooms[roomName] + 1
            : 1;
        this.primus.send('join', { room: roomName });
    }

    /**
     * Leaves all rooms
     */
    leaveAll() {
        const self = this;
        return new Promise((ful, rej) => {
            const roomArr = Object.keys(self.rooms);
            for (let iterator = 0; iterator < roomArr.length; iterator++) {
                self.primus.send('leave', { room: roomArr[iterator] });
            }
            self.rooms = {};
            return ful(true);
        });
    }

    /**
     * Leaves a room
     *
     * @param roomName The room to leave
     */
    leaveRoom(roomName) {
        this.rooms[roomName] = this.rooms[roomName]
            ? this.rooms[roomName] - 1
            : 0;
        if (!this.rooms[roomName] || this.rooms[roomName] === 0) {
            this.primus.send('leave', { room: roomName });
            Reflect.deleteProperty(this.rooms, roomName);
        }
    }
}
