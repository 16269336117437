import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpEvent,
    HttpResponse,
    HttpRequest,
    HttpHandler,
} from '@angular/common/http';
import { from, Observable, switchMap } from 'rxjs';
import { CognitoService } from './cognito.service';

@Injectable()
export class CognitoInterceptor implements HttpInterceptor {
    constructor(private cognitoService: CognitoService) {}
    intercept(
        httpRequest: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (window['NoKeyConfig'].authenticationProvider === 'cognito') {
            return new Observable((subscriber) => {
                this.cognitoService.Auth.currentSession().finally(() => {
                    subscriber.next();
                    subscriber.complete();
                });
            }).pipe(
                switchMap(() => {
                    return next.handle(httpRequest);
                })
            );
        }
        return next.handle(httpRequest);
    }
}
