// @flow
import { Injectable, Injector } from '@angular/core';
import { BaseService } from './base.service';
//Re-export

import type { EventFrontend as Event } from 'server/api/event/event';
export type { EventFrontend as Event } from 'server/api/event/event';

let AllEventTypes: string[] = [];
let AuditEventTypes: string[] = [];
let EventTypes: string[] = [];

@Injectable()
export class EventService extends BaseService<Event> {
    modelName = 'events';

    constructor(injector: Injector) {
        super(injector);
        AllEventTypes = window['NoKeyConfig'].AllEventTypes;
        AuditEventTypes = window['NoKeyConfig'].AuditEventTypes;
        EventTypes = window['NoKeyConfig'].EventTypes;
    }
}

export { AllEventTypes, AuditEventTypes, EventTypes };
