// @flow
import { Injectable, Injector } from '@angular/core';

import { BaseService } from './base.service';

import type { InterlockingGroupFrontend as InterlockingGroup } from 'server/api/interlockingGroup/interlockingGroup';
export type {
    HierarchyFrontend as Hierarchy,
    InterlockingGroupFrontend as InterlockingGroup,
} from 'server/api/interlockingGroup/interlockingGroup';

@Injectable()
export class InterlockingGroupService extends BaseService<InterlockingGroup> {
    modelName = 'interlockingGroups';
    constructor(injector: Injector) {
        super(injector);
    }
}
